<template>
	<div class="pui-form">
		<portcallberths-modals :modelName="modelName"></portcallberths-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('portcallberths.identificationData')">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="!isCreatingElement">
								<pui-date-field
									:id="`reqdate-${modelName}`"
									:label="$t('portcallberths.reqdate')"
									v-model="model.reqdate"
									toplabel
									required
									disabled
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="!isCreatingElement">
								<pui-text-field
									:id="`portcallberthid-portcallberths`"
									v-model="this.portfacilityname"
									:label="$t('portcallberths.portfacilitygisiscode')"
									disabled
									required
									toplabel
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="isCreatingElement">
								<pui-select
									:id="`portfacilityberthoperators-${modelName}`"
									:attach="`portfacilityberthoperators-${modelName}`"
									:label="$t('portcalls.portfacilityberthoperators')"
									:disabled="!isCreatingElement"
									toplabel
									clearable
									required
									v-model="model"
									modelName="vlupportfacilityberthoperators"
									:itemsToSelect="itemsToSelectportfacilityberthoperators"
									:modelFormMapping="{ berthoperatorid: 'berthoperatorid', berthoperatorname: 'berthoperatorname' }"
									:itemValue="['berthoperatorid', 'berthoperatorname']"
									itemText="berthoperatorname"
									:order="{ berthoperatorname: 'asc' }"
									:fixedFilter="filterPortFacility"
									reactive
								></pui-select>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="!isCreatingElement">
								<pui-text-field
									:id="`berthoperatorname-portcallberths`"
									v-model="model.berthoperatorname"
									:label="$t('portcallberths.berthoperatorname')"
									disabled
									required
									toplabel
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" v-if="!isCreatingElement">
								<pui-text-field
									:id="`portcallberthid-portcallberths`"
									v-model="model.portcallberthid"
									:label="$t('portcallberths.portcallberthid')"
									disabled
									required
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="!isCreatingElement">
								<pui-select
									:id="`berthingstatusid-${modelName}`"
									:attach="`berthingstatusid-${modelName}`"
									:label="$t('portcallberths.berthingstatusid')"
									disabled
									toplabel
									clearable
									required
									v-model="model"
									modelName="vlupberthingtstatus"
									:itemsToSelect="itemsToSelectBerthingStatus"
									:modelFormMapping="{ berthingstatusid: 'berthingstatusid' }"
									itemValue="berthingstatusid"
									itemText="statusdesc"
									reactive
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
				<v-col cols="12">
					<pui-field-set :title="$t('portcallberths.req')">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`reqeta-${modelName}`"
									:label="$t('portcallberths.reqeta')"
									v-model="model.reqeta"
									toplabel
									required
									:disabled="isFieldDisabled()"
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`reqetd-${modelName}`"
									:label="$t('portcallberths.reqetd')"
									v-model="model.reqetd"
									toplabel
									required
									:disabled="isFieldDisabled()"
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`reqberthingtypeid-${modelName}`"
									:attach="`reqberthingtypeid-${modelName}`"
									:label="$t('portcallberths.reqberthingtypeid')"
									:disabled="isFieldDisabled()"
									toplabel
									clearable
									required
									v-model="model"
									modelName="mberthingtypes"
									:itemsToSelect="itemsToSelectReqberthingtypeid"
									:modelFormMapping="{ berthingtypeid: 'reqberthingtypeid' }"
									itemValue="berthingtypeid"
									itemText="typedesc"
									:order="{ typedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`reqpurposecode-${modelName}`"
									:attach="`reqpurposecode-${modelName}`"
									:label="$t('portcallberths.reqpurposecode')"
									:disabled="isFieldDisabled()"
									toplabel
									clearable
									required
									v-model="model"
									modelName="mportcallpurposes"
									:itemsToSelect="itemsToSelectReqpurposecode"
									:modelFormMapping="{ portcallpurposecode: 'reqpurposecode' }"
									itemValue="portcallpurposecode"
									itemText="purposedesc"
									:order="{ purposedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>

							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`reqdraftarrival-portcallberths`"
									v-model="model.reqdraftarrival"
									:label="$t('portcallberths.reqdraftarrival')"
									:disabled="isFieldDisabled()"
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`reqdraftdeparture-portcallberths`"
									v-model="model.reqdraftdeparture"
									:label="$t('portcallberths.reqdraftdeparture')"
									:disabled="isFieldDisabled()"
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>

				<v-col cols="12">
					<pui-field-set :title="$t('portcallberths.auth')">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`autheta-${modelName}`"
									:label="$t('portcallberths.autheta')"
									v-model="model.autheta"
									toplabel
									disabled
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`authetd-${modelName}`"
									:label="$t('portcallberths.authetd')"
									v-model="model.authetd"
									toplabel
									disabled
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`authberthingtypeid-${modelName}`"
									:attach="`authberthingtypeid-${modelName}`"
									:label="$t('portcallberths.authberthingtypeid')"
									disabled
									toplabel
									clearable
									v-model="model"
									modelName="mberthingtypes"
									:itemsToSelect="itemsToSelectAuthberthingtypeid"
									:modelFormMapping="{ berthingtypeid: 'authberthingtypeid' }"
									itemValue="berthingtypeid"
									itemText="typedesc"
									:order="{ typedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`authpurposecode-${modelName}`"
									:attach="`authpurposecode-${modelName}`"
									:label="$t('portcallberths.authpurposecode')"
									disabled
									toplabel
									clearable
									v-model="model"
									modelName="mportcallpurposes"
									:itemsToSelect="itemsToSelectAuthpurposecode"
									:modelFormMapping="{ portcallpurposecode: 'authpurposecode' }"
									itemValue="portcallpurposecode"
									itemText="purposedesc"
									:order="{ purposedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`authnorayfrom-${modelName}`"
									v-model="model.authnorayfrom"
									:label="$t('portcallberths.authnorayfrom')"
									disabled
									toplabel
									type="number"
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`authnorayto-${modelName}`"
									v-model="model.authnorayto"
									:label="$t('portcallberths.authnorayto')"
									disabled
									toplabel
									type="number"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>

				<v-col cols="12">
					<pui-field-set :title="$t('portcallberths.act')">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`ata-${modelName}`"
									:label="$t('portcallberths.ata')"
									v-model="model.ata"
									toplabel
									disabled
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-date-field
									:id="`atd-${modelName}`"
									:label="$t('portcallberths.atd')"
									v-model="model.atd"
									toplabel
									disabled
									time
								></pui-date-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`actberthingtypeid-${modelName}`"
									:attach="`actberthingtypeid-${modelName}`"
									:label="$t('portcallberths.actberthingtypeid')"
									disabled
									toplabel
									clearable
									v-model="model"
									modelName="mberthingtypes"
									:itemsToSelect="itemsToSelectActberthingtypeid"
									:modelFormMapping="{ berthingtypeid: 'actberthingtypeid' }"
									itemValue="berthingtypeid"
									itemText="typedesc"
									:order="{ typedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`actpurposecode-${modelName}`"
									:attach="`actpurposecode-${modelName}`"
									:label="$t('portcallberths.actpurposecode')"
									disabled
									toplabel
									clearable
									v-model="model"
									modelName="mportcallpurposes"
									:itemsToSelect="itemsToSelectActpurposecode"
									:modelFormMapping="{ portcallpurposecode: 'actpurposecode' }"
									itemValue="portcallpurposecode"
									itemText="purposedesc"
									:order="{ purposedesc: 'asc' }"
									reactive
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`actnorayfrom-${modelName}`"
									v-model="model.actnorayfrom"
									:label="$t('portcallberths.actnorayfrom')"
									disabled
									toplabel
									type="number"
								></pui-text-field>
							</v-col>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`actnorayto-${modelName}`"
									v-model="model.actnorayto"
									:label="$t('portcallberths.actnorayto')"
									disabled
									toplabel
									type="number"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>

				<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<v-row dense>
						<!-- OBSERVATIONS -->
						<v-col cols="12">
							<pui-text-area
								:id="`observations-portcallberths`"
								v-model="model.observations"
								:label="$t('portcallberths.observations')"
								:disabled="formDisabled || model.berthingstatusid > 1"
								toplabel
								maxlength="256"
							></pui-text-area>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="!this.$store.getters.getSession.profiles.includes('SHAG') || !havePermissions(['WRITE_PORTCALLBERTHS'])"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import portcallberthsActions from './PortcallberthsActions';
import portcallberthsModals from './PortcallberthsModals.vue';

export default {
	name: 'portcallberths-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'portcallberths-modals': portcallberthsModals
	},
	data() {
		return {
			modelName: 'portcallberths',
			actions: portcallberthsActions.formactions,
			parentModelName: 'portcalls',
			portcallPort: null,
			portfacilitygisiscode: null,
			portfacilityname: null,
			portcode: null
		};
	},
	methods: {
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'portcallberths');
			}
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
				const opts = {
					model: 'portcalls',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'portcallid',
								op: 'eq',
								data: this.model.portcallid
							}
						]
					}
				};

				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data) {
						this.portcallPort = response.data.data[0].locode;
						this.portfacilitygisiscode = response.data.data[0].portfacilitygisiscode;
						this.portfacilityname = response.data.data[0].portfacilityname;
						this.portcode = response.data.data[0].portcode;
					}
				});
			}
		},
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		isFieldDisabled() {
			return this.formDisabled || this.model.berthingstatusid > 1 || !this.$store.getters.getSession.profiles.includes('SHAG');
		}
	},
	computed: {
		itemsToSelectReqberthingtypeid() {
			return [{ berthingtypeid: this.model.reqberthingtypeid }];
		},
		itemsToSelectAuthberthingtypeid() {
			return [{ berthingtypeid: this.model.authberthingtypeid }];
		},
		itemsToSelectActberthingtypeid() {
			return [{ berthingtypeid: this.model.actberthingtypeid }];
		},
		itemsToSelectPortFacilities() {
			return [{ portfacilitygisiscode: this.model.portfacilitygisiscode }];
		},
		itemsToSelectportfacilityberthoperators() {
			return [{ berthoperatorid: this.model.berthoperatorid }, { berthoperatorname: this.model.berthoperatorname }];
		},
		itemsToSelectReqpurposecode() {
			return [{ portcallpurposecode: this.model.reqpurposecode }];
		},
		itemsToSelectAuthpurposecode() {
			return [{ portcallpurposecode: this.model.authpurposecode }];
		},
		itemsToSelectActpurposecode() {
			return [{ portcallpurposecode: this.model.actpurposecode }];
		},
		itemsToSelectBerthingStatus() {
			return [{ berthingstatusid: this.model.berthingstatusid }];
		},
		filterCountry() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'locode', op: 'eq', data: this.portcallPort }]
					}
				]
			};
		},
		filterPortFacility() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portfacilitygisiscode', op: 'eq', data: this.portfacilitygisiscode },
							{ field: 'portcode', op: 'eq', data: this.portcode }
						]
					}
				]
			};
		}
	},
	created() {}
};
</script>
